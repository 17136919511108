// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_skeleton__dIVaP {\n  --base-color: lightsteelblue !important;\n  opacity: 0.15;\n}", "",{"version":3,"sources":["webpack://./src/components/Skeleton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,aAAA;AACF","sourcesContent":[".skeleton {\n  --base-color: lightsteelblue !important;\n  opacity: 0.15;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": "styles_skeleton__dIVaP"
};
export default ___CSS_LOADER_EXPORT___;

import bridge from "@vkontakte/vk-bridge";
import { getAppUserId } from "mini-juice/vk";

import { initEruda } from "./initEruda";

export const initApp = () => {
  if ([272503996, 126973744, 19568187].includes(getAppUserId())) {
    initEruda();
  }

  if (bridge.supports("VKWebAppSetViewSettings")) {
    bridge
      .send("VKWebAppSetViewSettings", {
        status_bar_style: "dark",
        navigation_bar_color: "#fff",
        action_bar_color: "#fff",
      })
      .catch(console.log);
  }

  bridge.send("VKWebAppInit");
};

import { Routes, Route } from "react-router-dom";

import { Home, Awards, Rules } from "./containers";

import "./assets/scss/style.scss";

function App() {
  return (
    <div className="main-wrapper">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/rules" element={<Rules />} />
      </Routes>
    </div>
  );
}

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__mKKTT {\n  width: 100%;\n  padding: 16px;\n  background-color: #fff;\n  border-radius: 16px;\n  font-size: 17px;\n  font-weight: 700;\n  line-height: 1.29;\n  text-transform: uppercase;\n  letter-spacing: 1.2%;\n  color: #000;\n  position: relative;\n  transition: background-color 0.1s linear;\n  z-index: 10;\n  font-family: \"FreeSet\";\n}\n@media (min-width: 576px) {\n  .styles_button__mKKTT {\n    font-size: 19px;\n    line-height: 1.26;\n  }\n}\n@media (min-width: 1400px) {\n  .styles_button__mKKTT {\n    font-size: 21px;\n    line-height: 1.23;\n  }\n}\n.styles_button__mKKTT:hover {\n  background-color: rgba(255, 255, 255, 0.88);\n}\n.styles_button__mKKTT span {\n  position: relative;\n  top: 2px;\n}", "",{"version":3,"sources":["webpack://./src/components/GetInLineBtn/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EAEA,sBAAA;EACA,mBAAA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,oBAAA;EACA,WAAA;EAEA,kBAAA;EACA,wCAAA;EACA,WAAA;EAeA,sBAAA;AAhBF;AAGE;EAlBF;IAmBI,eAAA;IACA,iBAAA;EAAF;AACF;AAEE;EAvBF;IAwBI,eAAA;IACA,iBAAA;EACF;AACF;AACE;EACE,2CAAA;AACJ;AAGE;EACE,kBAAA;EACA,QAAA;AADJ","sourcesContent":[".button {\n  width: 100%;\n  padding: 16px;\n\n  background-color: #fff;\n  border-radius: 16px;\n\n  font-size: 17px;\n  font-weight: 700;\n  line-height: 1.29;\n  text-transform: uppercase;\n  letter-spacing: 1.2%;\n  color: #000;\n\n  position: relative;\n  transition: background-color 0.1s linear;\n  z-index: 10;\n\n  @media (min-width: 576px) {\n    font-size: 19px;\n    line-height: 1.26;\n  }\n\n  @media (min-width: 1400px) {\n    font-size: 21px;\n    line-height: 1.23;\n  }\n\n  &:hover {\n    background-color: rgba(255, 255, 255, 0.88);\n  }\n  font-family: \"FreeSet\";\n\n  span {\n    position: relative;\n    top: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__mKKTT"
};
export default ___CSS_LOADER_EXPORT___;

import axios from "../../configs/api";

const winnerApi = {
  getInviteLink(walletAddress) {
    const tonData = new FormData();

    tonData.append("wallet_address", walletAddress);

    return axios.post("/telegram.getInviteLink", tonData);
  },
};

export default winnerApi;

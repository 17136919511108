import { memo, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { rules } from "features/rules";
import {
  GetInLineBtn,
  Header,
  GoBackBtn,
  List,
  Skeleton,
  Image,
} from "components";
import { useScroll } from "hooks";
import { selectCurrentParticipant } from "services/store/selectors";

import lineImg from "assets/img/line.png";
import { ReactComponent as ChatIcon } from "assets/img/icons/chat-sm.svg";

function Rules() {
  const { scroll } = useScroll();
  const { item: isAlreadyInLine } = useSelector(selectCurrentParticipant);
  const [imgLoading, setImgLoading] = useState(true);

  let windowWidth;

  if (typeof window !== "undefined") {
    windowWidth = window.innerWidth;
  }

  const getScrollClassNameStatus = () => {
    if (windowWidth < 400) {
      return scroll > 205;
    }

    return scroll > 237;
  };

  const scrollClassNameStatus = getScrollClassNameStatus();

  return (
    <div className="rules">
      <Header className="rules-header" />
      <GoBackBtn className="rules-btn" />
      <div className="rules-wrapper">
        <div className="rules-img__wrapper">
          {imgLoading && <Skeleton className="rules-img__skeleton" />}
          <div className="rules-img">
            <Image src={lineImg} onLoad={() => setImgLoading(false)} />
          </div>
        </div>
        <div className="rules-inner">
          <div
            className={cn("rules-inner__title", {
              scroll: scrollClassNameStatus,
            })}
          >
            <h1>Правила игры</h1>
          </div>
          <div
            className={cn("rules-inner__content", {
              scroll: scrollClassNameStatus,
            })}
          >
            <List items={rules} itemClassName="rules-inner__item" />
          </div>
        </div>
        <footer className="rules-bottom">
          <h3 className="rules-bottom__title">
            Общайтесь с другими игроками, пока стоите в Очереди
          </h3>
          {/* <div className="rules-bottom__icons">
            <a href="/">
              <ChatIcon className="icon" />
              Чат
            </a>
          </div> */}
          {!isAlreadyInLine && (
            <div className="rules-bottom__btn-wrapper">
              <GetInLineBtn />
            </div>
          )}
        </footer>
      </div>
    </div>
  );
}

export default memo(Rules);

import Konva from "konva";
import {
  konvaLoadImageWithParams,
  konvaRenderLayerToBase64,
  konvaSafeAddImage,
} from "mini-juice/konva";

import BG_IMAGE from "../assets/img/story-bg.png";

export const generateStory = async () => {
  const STORY_WIDTH = 1080;
  const STORY_HEIGHT = 1920;

  const layer = new Konva.Layer();

  const shareBgImage = await konvaLoadImageWithParams(BG_IMAGE, {
    width: STORY_WIDTH,
    height: STORY_HEIGHT,
  });

  konvaSafeAddImage(layer, shareBgImage);

  return konvaRenderLayerToBase64(layer, {
    previewScale: 0.2,
    previewInConsole: "on",
  });
};

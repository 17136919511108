import { useEffect, useState } from "react";

const useScroll = () => {
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const bindEvents = () => {
    document.addEventListener("scroll", handleScroll);
  };

  const unbindEvents = () => {
    document.removeEventListener("scroll", handleScroll);
  };

  useEffect(() => {
    bindEvents();

    return unbindEvents;
  });

  return {
    scroll,
  };
};

export default useScroll;

import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import cn from "classnames";

import styles from "./styles.module.scss";
import CloseIcon from "../../assets/img/icons/close.svg";

const Modal = ({ className, onClose, children }) => {
  const modalEl = useRef();
  const modalInnerEl = useRef();

  const handleOutsideClick = ({ target }) => {
    if (target.contains(modalEl.current)) {
      onClose();
    }
  };

  const handleCloseByEscape = (e) => e.code === "Escape" && onClose();

  const bindEvents = () => {
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("keyup", handleCloseByEscape);
  };

  const unbindEvents = () => {
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("keyup", handleCloseByEscape);
  };

  const lockScroll = () => document.body.classList.add("locked");
  const unlockScroll = () => document.body.classList.remove("locked");

  const initModal = () => {
    bindEvents();
    lockScroll();
  };

  const destroyModal = () => {
    unbindEvents();
    unlockScroll();
  };

  const startAnimation = () => {
    gsap.to(modalInnerEl.current, {
      opacity: 1,
      delay: 0.1,
    });
  };

  useEffect(() => {
    initModal();
    startAnimation();

    return destroyModal;
  }, [handleOutsideClick]);

  return (
    <div className={styles.overlay} ref={modalEl}>
      <div className={cn(styles.modal, className)} ref={modalInnerEl}>
        <button type="button" className={styles.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;

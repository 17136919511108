import { memo } from "react";
import cn from "classnames";

import { useActions } from "services/store/slices/home";
import { MOBILE_WIDTH } from "services/constants";

import styles from "./styles.module.scss";

const GetInLineBtn = ({ className }) => {
  const { setModalVisibility } = useActions();
  let isDesktop;

  if (typeof window !== "undefined") {
    isDesktop = window.innerWidth > MOBILE_WIDTH;
  }

  const showModalScanQR = () => {
    setModalVisibility({ modal: "ScanQR", value: true });
  };

  const openTonkeeper = () => {
    window.open(
      "https://app.tonkeeper.com/ton-login/staging.line.juicedev.org/api/auth"
    );
  };

  return (
    <button
      type="button"
      className={cn(styles.button, className)}
      onClick={isDesktop ? showModalScanQR : openTonkeeper}
    >
      <span>Встать в очередь</span>
    </button>
  );
};

export default memo(GetInLineBtn);

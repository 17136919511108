import React, { memo, useRef, useState, useEffect } from "react";
import cn from "classnames";

import Image from "../Image";

import styles from "./styles.module.scss";
import Skeleton from "../Skeleton";

const List = ({
  items,
  className,
  itemClassName,
  withLineBreak,
  isLoading,
}) => {
  const [imgsLoading, setImgsLoading] = useState({});
  const firstRun = useRef(true);

  useEffect(() => {
    if (!firstRun.current || !items.length) return;

    const object = {};

    items.forEach((item, idx) => {
      const { award_img, img } = item;

      if (award_img || img) {
        object[idx] = true;
      }
    });

    setImgsLoading(object);
    firstRun.current = false;
  }, [items]);

  const onRemoveImgLoading = (idx) => {
    setImgsLoading((prev) => ({ ...prev, [idx]: false }));
  };

  const formatWalletAddress = (walletAddress) =>
    `${walletAddress.slice(0, 5)}...${walletAddress.slice(-5)}`;

  const renderListItem = (
    {
      id,
      title,
      description,
      award_description,
      wallet_address,
      after,
      award,
      award_img,
      img,
    },
    idx
  ) => {
    return (
      <div
        className={cn(styles.item, itemClassName, {
          [styles.withLineBreak]: withLineBreak,
        })}
        key={id || idx}
      >
        <span className={styles.num}>{idx + 1}</span>
        <div className={styles.text}>
          <div>
            {isLoading ? (
              <Skeleton />
            ) : (
              title || formatWalletAddress(wallet_address)
            )}
          </div>
          {isLoading && (
            <span>
              <Skeleton className={styles.deskSkeleton} />
            </span>
          )}
          {(description || award_description) && (
            <span className={styles.desc}>
              {description || award_description}
            </span>
          )}
        </div>
        {after ||
          (award && (
            <div className={styles.after}>
              <h4 className="awards-value__wrapper">
                <span className="awards-value">100 TON</span>
              </h4>
            </div>
          ))}
        {(img || award_img) && (
          <div className={cn(styles.after, styles.afterWithImg)}>
            {imgsLoading[idx] && <Skeleton className={styles.imgSkeleton} />}
            <Image
              className={cn({ [styles.hidden]: imgsLoading[idx] })}
              src={img || award_img}
              onLoad={() => onRemoveImgLoading(idx)}
              width={69}
              height={22}
              layout="fill"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={cn(styles.list, className)}>
      {items.length
        ? items.map(renderListItem)
        : Array(3).fill(0).map(renderListItem)}
    </div>
  );
};

export default memo(List);

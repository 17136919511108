import { createSlice } from "@reduxjs/toolkit";

import { useDispatchActions } from "../../../hooks";

const initialState = {
  lineParticipants: [],
  lineTotalCount: 0,
  isWinner: false,
  isNotificationsEnabled: false,
  modalsVisibility: {
    ScanQR: false,
    AfterEnteringLine: false,
  },
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setModalVisibility: (state, { payload }) => {
      state.modalsVisibility[payload.modal] = payload.value;
    },
    setLineTotalCount: (state, { payload }) => {
      state.lineTotalCount = payload;
    },
    setLineParticipants: (state, { payload }) => {
      state.lineParticipants = payload;
    },
    addLineParticipant: (state, { payload }) => {
      state.lineParticipants = [...state.lineParticipants, payload];
    },
    deleteFirstParticipant: (state) => {
      state.lineParticipants.shift();
    },
    setIsWinner: (state, { payload }) => {
      state.isWinner = payload;
    },
    setIsNotificationsEnabled: (state, { payload }) => {
      state.isNotificationsEnabled = payload;
    },
  },
});

export const { actions, reducer } = homeSlice;
export const useActions = () => useDispatchActions(actions);

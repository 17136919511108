import React, { useState, useEffect } from "react";
import cn from "classnames";

import { awardApi, winnerApi } from "services/api";
import { GoBackBtn, List, Header } from "components";
import { useScroll } from "hooks";

import { ReactComponent as TrophyIcon } from "assets/img/icons/trophy.svg";
import { ReactComponent as UserIcon } from "assets/img/icons/user.svg";
import { ReactComponent as ArrowIcon } from "assets/img/icons/arrow-bottom.svg";

const Awards = () => {
  const { scroll } = useScroll();

  const [isAwardsOpened, setIsAwardsOpened] = useState(false);
  const [awardsList, setAwardsList] = useState([]);
  const [winnerList, setWinnerList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const openAwards = () => {
    setIsAwardsOpened(true);
  };

  const renderBlock = ({ icon: Icon, title, items, withMoreBtn }) => {
    if (!items.length && !isLoading) return;

    const getCurrentItems = () => {
      if (isAwardsOpened) return items;
      return items.slice(0, 3);
    };

    return (
      <div className="awards-block">
        <div className="awards-block__top">
          <Icon className="awards-block__icon" />
          <h4>{title}</h4>
        </div>
        <List
          items={withMoreBtn ? getCurrentItems() : items}
          className={cn("awards-block__list", {
            opened: isAwardsOpened || !withMoreBtn || items.length <= 3,
          })}
          itemClassName="awards-block__list-item"
          isLoading={isLoading}
          withLineBreak
        />
        {!isAwardsOpened && withMoreBtn && items.length > 3 && (
          <div className="awards-block__bottom">
            <button
              type="button"
              className="awards-block__see-more"
              onClick={openAwards}
            >
              See All <ArrowIcon />
            </button>
          </div>
        )}
      </div>
    );
  };

  const getData = () => {
    Promise.all([awardApi.getList(), winnerApi.getList()]).then(
      ([{ data: awardsData }, { data: winnersData }]) => {
        setAwardsList(awardsData.response);
        setWinnerList(winnersData.response);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="awards">
      <Header className="awards-header" />
      <GoBackBtn className="awards-top__btn" />
      <div
        className={cn("awards-top", {
          scroll: scroll > 1,
        })}
      >
        <h3 className="awards-top__title">Победители</h3>
      </div>
      <div className="awards-content">
        {renderBlock({
          icon: TrophyIcon,
          title: "Призы",
          items: awardsList,
          withMoreBtn: true,
        })}
        {renderBlock({
          icon: UserIcon,
          title: "Победители",
          items: winnerList,
        })}
      </div>
    </div>
  );
};

export default Awards;

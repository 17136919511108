// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__HcAZp {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 33.33px;\n  height: 33.33px;\n  position: fixed;\n  top: calc(env(safe-area-inset-top) + 12.33px);\n  left: 15.33px;\n  z-index: 20;\n  border-radius: 50%;\n  background-color: rgba(255, 255, 255, 0.12);\n  -webkit-backdrop-filter: blur(40px);\n          backdrop-filter: blur(40px);\n  transition: background-color 0.1s linear;\n}\n.styles_button__HcAZp:hover {\n  background-color: rgba(255, 255, 255, 0.16);\n}\n@media (min-width: 576px) {\n  .styles_button__HcAZp {\n    position: static;\n    margin-left: 27.33px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/GoBackBtn/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,cAAA;EACA,eAAA;EAEA,eAAA;EACA,6CAAA;EACA,aAAA;EACA,WAAA;EAEA,kBAAA;EACA,2CAAA;EACA,mCAAA;UAAA,2BAAA;EAEA,wCAAA;AAHF;AAKE;EACE,2CAAA;AAHJ;AAME;EAvBF;IAwBI,gBAAA;IACA,oBAAA;EAHF;AACF","sourcesContent":[".button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  width: 33.33px;\n  height: 33.33px;\n\n  position: fixed;\n  top: calc(env(safe-area-inset-top) + 12.33px);\n  left: 15.33px;\n  z-index: 20;\n\n  border-radius: 50%;\n  background-color: rgba(255, 255, 255, 0.12);\n  backdrop-filter: blur(40px);\n\n  transition: background-color 0.1s linear;\n\n  &:hover {\n    background-color: rgba(255, 255, 255, 0.16);\n  }\n\n  @media (min-width: 576px) {\n    position: static;\n    margin-left: 27.33px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__HcAZp"
};
export default ___CSS_LOADER_EXPORT___;

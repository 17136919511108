import axios from "../../configs/api";

const queueApi = {
  add(walletAddress) {
    const userData = new FormData();
    userData.append("wallet_address", walletAddress);

    return axios.post("/queue.add", userData);
  },
  /**
   * @param {object} data
   * @property {string} wallet_address
   * @return {Promise}
   */
  getList(data) {
    return axios.post("/queue.getList", data);
  },
  getTotalCount() {
    return axios.post("/queue.getTotalCount");
  },
};

export default queueApi;

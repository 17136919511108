import * as eruda from "eruda";

export const initEruda = () => {
  const el = document.createElement("div");
  document.body.appendChild(el);

  eruda.init({
    container: el,
    tool: ["console", "elements", "network"],
  });
  eruda.position({ x: 200, y: 200 });
};

import { createSlice } from "@reduxjs/toolkit";

import { useDispatchActions } from "../../../hooks";

const initialState = {
  telegramInviteLink: "",
  authId: "",
};

export const userSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setTelegramInviteLink: (state, { payload }) => {
      state.telegramInviteLink = payload;
    },
    setAuthId: (state, { payload }) => {
      state.authId = payload;
    },
  },
});

export const { actions, reducer } = userSlice;
export const useActions = () => useDispatchActions(actions);

import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import styles from "./styles.module.scss";

import { ReactComponent as ChevronLeftIcon } from "../../assets/img/icons/chevron-left.svg";

const GoBackBtn = ({ className }) => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <button
      type="button"
      className={cn(styles.button, className)}
      onClick={goBack}
    >
      <ChevronLeftIcon />
    </button>
  );
};

export default memo(GoBackBtn);

import React, { memo } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import { useActions } from "services/store/slices/home";
import { useScroll } from "hooks";

import { ReactComponent as QuestionIcon } from "assets/img/icons/question.svg";

import styles from "./styles.module.scss";

const Header = ({ className }) => {
  const { setIsWinner } = useActions();
  const { scroll } = useScroll();

  return (
    <header
      className={cn(styles.header, className, {
        [styles.scroll]: scroll > 20,
      })}
    >
      <Link to="/" className={styles.logo} onClick={() => setIsWinner(false)}>
        Line <span>iii</span>
      </Link>
      <div className={styles.icons}>
        <Link className={styles.question} to="/rules">
          <QuestionIcon className="icon" />
        </Link>
      </div>
    </header>
  );
};

export default memo(Header);

import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Tooltip } from "components";

import { ReactComponent as QuestionIcon } from "assets/img/icons/ellipse-question.svg";
import {
  selectCurrentParticipant,
  selectLineParticipants,
} from "services/store/selectors";

const AverageTimeLeft = ({ tooltipVisible, isMobile, ...props }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const interval = useRef();

  const lineParticipants = useSelector(selectLineParticipants);
  const { item: currentParticipant, index: currentParticipantIndex } =
    useSelector(selectCurrentParticipant);

  const removeTimeLeft = () => {
    setTimeLeft({
      hours: 0,
      minutes: 0,
      seconds: 0,
      totalSecondsLeft: 0,
    });
  };

  const getCurrentTime = (value) => {
    return String(value).length === 1 ? `0${value}` : value;
  };

  const onSetTimeLeft = (totalSecondsLeft) => {
    if (currentParticipantIndex == 0) return removeTimeLeft();
    if (totalSecondsLeft <= 0) return removeTimeLeft();

    const hours = Math.floor(totalSecondsLeft / (60 * 60));
    const minutes = Math.floor(totalSecondsLeft / 60) - hours * 60;
    const seconds = totalSecondsLeft % 60;

    setTimeLeft({ hours, minutes, seconds, totalSecondsLeft });
  };

  useEffect(() => {
    if (currentParticipantIndex == 0) return removeTimeLeft();

    let totalSecondsLeft;

    if (currentParticipant) {
      totalSecondsLeft = Math.floor(
        (currentParticipant.prev_user_expiry_date - Date.now()) / 1000
      );
    } else {
      const lastParticipant = lineParticipants[lineParticipants.length - 1];

      totalSecondsLeft = Math.floor(
        ((lastParticipant?.last_user_expiry_date ||
          lastParticipant?.expiry_date) -
          Date.now()) /
          1000
      );
    }

    onSetTimeLeft(totalSecondsLeft);
  }, [lineParticipants]);

  useEffect(() => {
    if (!timeLeft) return;

    clearInterval(interval.current);

    interval.current = setInterval(() => {
      onSetTimeLeft(timeLeft.totalSecondsLeft - 1);
    }, 1000);

    return () => {
      clearInterval(interval.current);
    };
  }, [timeLeft]);

  return (
    <div className="home-data__item">
      <div className="home-data__title">
        Осталось
        <Tooltip
          value="Среднее время ожидания"
          visible={tooltipVisible}
          placement={isMobile ? "left" : "top"}
        >
          <div
            className="home-data__icon-wrapper"
            {...props}
            onClick={isMobile ? props.onClick : null}
          >
            <QuestionIcon
              fill={tooltipVisible ? "#fff" : "rgba(255, 255, 255, 0.4)"}
            />
          </div>
        </Tooltip>
      </div>
      <h3 className="home-data__value">
        {timeLeft?.hours ? getCurrentTime(timeLeft.hours) : "00"}
        <span>:</span>
        {timeLeft?.minutes ? getCurrentTime(timeLeft.minutes) : "00"}
        <span>:</span>
        {timeLeft?.seconds ? getCurrentTime(timeLeft.seconds) : "00"}
      </h3>
    </div>
  );
};

export default memo(AverageTimeLeft);

import React, { memo } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

const Image = ({ src, width, height, onLoad, className }) => {
  return (
    <img
      onLoad={onLoad}
      src={src}
      className={cn(styles.img, className)}
      width={width}
      height={height}
    />
  );
};

export default memo(Image);

import React, { memo } from "react";
import cn from "classnames";
import { bridgeShareStoryWithOpenApp } from "mini-juice/vk";
import { useSelector } from "react-redux";

import { generateStory } from "helpers";
import { useActions } from "../../services/store/slices/home";
import { selectTelegramInviteLink } from "../../services/store/selectors";
import { Modal } from "../../components";

import styles from "./styles.module.scss";

import CheckIcon from "../../assets/img/icons/check.svg";
import TelegramIcon from "../../assets/img/icons/telegram-2.svg";
import ChatIcon from "../../assets/img/icons/chat.svg";
import ShareIcon from "../../assets/img/icons/share.svg";

const AfterEnteringLine = () => {
  const { setModalVisibility } = useActions();

  const linkToTelegramBot = useSelector(selectTelegramInviteLink);

  const closeModal = () =>
    setModalVisibility({ modal: "AfterEnteringLine", value: false });

  const onShare = async () => {
    const base64 = await generateStory();

    if (base64 !== false) {
      bridgeShareStoryWithOpenApp(base64, "open")
        .then(() => {
          console.log("then");
        })
        .catch(console.log);
    }
  };

  return (
    <Modal onClose={closeModal} className={cn(styles.modal)}>
      <div className={styles.top}>
        <CheckIcon />
      </div>
      <h3 className={styles.title}>Вы в очереди. Что дальше?</h3>
      <div className={styles.contentBlock}>
        <div className={styles.contentItem}>
          <div className={styles.contentIconWrapper}>
            <TelegramIcon />
          </div>
          <div className={styles.contentItemInner}>
            <div className={styles.contentText}>
              Подписывайтесь на наше сообщество ВКонтакте
            </div>
            <div className={styles.contentLinks}>
              <a
                href={linkToTelegramBot}
                className={styles.contentLink}
                target="_blank"
                rel="noreferrer"
              >
                Подписаться
              </a>
            </div>
          </div>
        </div>
        <div className={styles.contentItem}>
          <div className={styles.contentIconWrapper}>
            <ChatIcon />
          </div>
          <div className={styles.contentItemInner}>
            <div className={styles.contentText}>
              Давайте поговорим о крипте, жизни и игре The Line в нашем чате
            </div>
            <div className={styles.contentLinks}>
              <a href="/" className={styles.contentLink} target="_blank">
                Вступить в чат
              </a>
            </div>
          </div>
        </div>
      </div>
      <button type="button" className={styles.shareBtn} onClick={onShare}>
        <ShareIcon />
        Поделиться с друзьями
      </button>
    </Modal>
  );
};

export default memo(AfterEnteringLine);

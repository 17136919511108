import React, { memo, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import gsap from "gsap";

import { Image, Skeleton } from "components";

import awardImg from "assets/img/award.png";
import nftImg from "assets/img/nft-1.png";
import nftImg2 from "assets/img/nft-2.png";
import nftImg3 from "assets/img/nft-3.png";
import nftImg4 from "assets/img/nft-4.png";
import nftImg5 from "assets/img/nft-5.png";

const top = () => {
  const [imgLoading, setImgLoading] = useState(true);

  const firstAward = useRef(null);
  const secondAward = useRef(null);
  const tl = useRef(null);

  const startAnimation = () => {
    tl.current = gsap
      .timeline({ repeat: -1, repeatDelay: 0, repeatRefresh: true })
      .fromTo(
        firstAward.current,
        { display: "none", opacity: 0, scale: 0.5 },
        {
          display: "block",
          opacity: 1,
          scale: 1,
        }
      )
      .to(
        firstAward.current,
        {
          display: "none",
          opacity: 0,
          scale: 0.5,
        },
        5
      )
      .fromTo(
        secondAward.current,
        {
          opacity: 0,
          display: "none",
          scale: 0.5,
        },

        {
          opacity: 1,
          display: "flex",
          scale: 1,
        },
        5.5
      )
      .to(
        secondAward.current,
        {
          opacity: 0,
          scale: 0.5,
        },
        10.5
      );
  };

  useEffect(() => {
    !imgLoading && startAnimation();
  }, [imgLoading]);

  return (
    <div className="home-top">
      <h4 className="home-top__title">Победитель получит</h4>
      <div className="home-top__first-animate" ref={firstAward}>
        {imgLoading && (
          <Skeleton className="home-top__first-animate-skeleton" />
        )}
        <div className="home-top__img">
          <Image src={awardImg} onLoad={() => setImgLoading(false)} />
        </div>
      </div>
      <div className="home-top__second-animate" ref={secondAward}>
        <div className={cn("second-animate__left")}>
          <h4>Или разные NFT</h4>
          <Link to="/awards">Смотреть все</Link>
        </div>
        <div className="second-animate__imgs">
          <Image src={nftImg} className="second-animate__img" />
          <Image src={nftImg2} className="second-animate__img" />
          <Image src={nftImg3} className="second-animate__img" />
          <Image src={nftImg4} className="second-animate__img" />
          <Image src={nftImg5} className="second-animate__img" />
        </div>
      </div>
    </div>
  );
};

export default memo(top);

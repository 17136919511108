import React, { memo, useMemo, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ModalAfterEnteringLine } from "modals";
import { GetInLineBtn, Header, Line } from "components";

import { useActions } from "services/store/slices/home";
import { useActions as useUserActions } from "services/store/slices/user";
import {
  selectIsWinner,
  selectLineParticipants,
  selectModalsVisibility,
} from "services/store/selectors";
import { queueApi } from "services/api";
import { HomeTop, LineData } from "features/home";

import { ReactComponent as TrophyIcon } from "assets/img/icons/trophy.svg";
import { ReactComponent as ChevronIcon } from "assets/img/icons/chevron-right.svg";
import { ReactComponent as ConfettiIcon } from "assets/img/icons/confetti.svg";
import { ReactComponent as EyesIcon } from "assets/img/icons/eyes.svg";

function Home() {
  const navigate = useNavigate();
  const { setLineParticipants, setLineTotalCount } = useActions();
  const { setTelegramInviteLink } = useUserActions();

  const [lineLoading, setLineLoading] = useState(true);
  const firstRequest = useRef(true);

  const isWinner = useSelector(selectIsWinner);

  const interval = useRef();

  const lineParticipants = useSelector(selectLineParticipants);

  const currentPatricipant = useMemo(() => {
    return lineParticipants.find(
      (participant) => participant.is_notification_enabled !== undefined
    );
  }, [lineParticipants]);

  const {
    ScanQR: scanQRVisibility,
    AfterEnteringLine: afterEnteringLineVisibility,
  } = useSelector(selectModalsVisibility);

  const switchToAwardsPage = () => {
    navigate("/awards");
  };

  const getQueueData = async () => {
    const tonAddressData = new FormData();
    const walletAddress = localStorage.getItem("walletAddress");

    tonAddressData.append("wallet_address", walletAddress || "");

    await Promise.all([
      queueApi.getList(tonAddressData),
      queueApi.getTotalCount(),
    ])
      .then(([{ data: listData }, { data: totalCountData }]) => {
        setLineParticipants(listData.response);
        setLineTotalCount(totalCountData.response);

        if (firstRequest.current) {
          setTelegramInviteLink(
            listData.response.find(
              (item) => item.is_notification_enabled !== undefined
            )?.telegram_invite_link
          );

          firstRequest.current = false;
        }
      })
      .catch(console.log)
      .finally(() => setLineLoading(false));
  };

  useEffect(() => {
    getQueueData();

    interval.current = setInterval(() => {
      !lineLoading && getQueueData();
    }, 5000);

    return () => {
      clearInterval(interval.current);
    };
  }, [lineLoading]);

  const winnerContent = (
    <div className="home-winner">
      <ConfettiIcon />
      <h3 className="home-winner__title">Вы победили!</h3>
      <span className="home-winner__hint">
        "Вскоре, мы отправим приз на ваш адрес кошелька, с которым вы вставали в
        Очередь"
      </span>
    </div>
  );

  const emptyLineContent = (
    <div className="home-empty">
      <EyesIcon />
      <h3 className="home-empty__title">Ожидаем подключения новых игроков</h3>
      <div className="home-empty__text">
        Сейчас в Очереди никого нет. Рискнете стать первым?
      </div>
    </div>
  );

  return (
    <div className="home">
      <Header />
      <div className="page-inner">
        <div className="home-content">
          <HomeTop />
          <LineData />
          {isWinner && winnerContent}
          {!isWinner && (
            <>
              <button
                type="button"
                className="home-awards"
                onClick={switchToAwardsPage}
              >
                <TrophyIcon className="home-awards__trophy-icon" />
                Победители и призы
                <ChevronIcon className="home-awards__chevron-icon" />
              </button>
              {!currentPatricipant && !lineLoading && (
                <div className="home-btn__wrapper">
                  <GetInLineBtn className="home-btn" />
                </div>
              )}
              {(!!lineParticipants.length || lineLoading) && (
                <Line items={lineParticipants} />
              )}
              {!lineParticipants.length && !lineLoading && emptyLineContent}
            </>
          )}
          {!lineLoading && !!lineParticipants.length && (
            <div className="home-bottom">
              <div className="home-bottom__back" />
              <div className="home-bottom__front">
                <div className="home-bottom__inner">
                  <div className="home-bottom__left" />
                  <div className="home-bottom__mid" />
                  <div className="home-bottom__right" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {afterEnteringLineVisibility && <ModalAfterEnteringLine />}
    </div>
  );
}

export default memo(Home);

export const rules = [
  {
    title:
      "Вы встаёте в конец очереди, и вам придется ждать некоторое время, чтобы пройти вперёд.",
    id: 1,
  },
  {
    title: "Каждый человек стоит в очереди разное время.",
    id: 2,
  },
  {
    title:
      "Последний человек в очереди получит приз. Вы должны быть единственным человеком в очереди, чтобы получить приз, никто не должен стоять позади вас.",
    id: 3,
  },
  {
    title:
      "Если вы дождались, вышли на первое место и выбыли, то авторизуйтесь еще раз, чтобы снова встать в очередь.",
    id: 4,
  },
  {
    title:
      "Некоторые люди в очереди получат специальные призы только потому, что им повезло по каким-то случайным причинам.",
    id: 5,
  },
];

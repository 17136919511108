import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import cn from "classnames";

import { useInView } from "react-intersection-observer";
import { useActions } from "../../services/store/slices/home";
import {
  selectCurrentParticipant,
  selectExpiryDate,
  selectIsNotificationsEnabled,
  selectTelegramInviteLink,
} from "../../services/store/selectors";
import { Skeleton } from "../index";

import styles from "./styles.module.scss";

const Line = ({ items }) => {
  const { deleteFirstParticipant, setIsWinner } = useActions();
  const expiryDate = useSelector(selectExpiryDate);
  const isNotificationsEnabled = useSelector(selectIsNotificationsEnabled);
  const linkToTelegramBot = useSelector(selectTelegramInviteLink);
  const { item: currentParticipant, index: currentParticipantIndex } =
    useSelector(selectCurrentParticipant);

  const [timeLeft, setTimeLeft] = useState(0);

  const interval = useRef(null);
  const timeline = useRef(null);
  const listEl = useRef(null);
  const q = gsap.utils.selector(listEl);

  const [ref, inView] = useInView();

  let viewWidth;

  if (typeof window !== "undefined") {
    viewWidth = window.innerWidth;
  }

  const getCurrentTime = (time) => {
    return String(time).length === 1 ? `0${time}` : time;
  };

  const startExitAnimation = () => {
    timeline.current = gsap
      .timeline()
      .to(q(".first"), {
        opacity: 0,
        x: "-120px",
        duration: 0.65,
      })
      .to(
        listEl.current,
        {
          top: getCurrentTop(),
          duration: 0.65,
        },
        ">"
      )
      .to(
        q(".second"),
        {
          borderRadius: items.length === 2 ? "24px" : "24px 24px 0 0",
          duration: 0.5,
        },
        ">"
      )
      .to(
        listEl.current,
        {
          top: "0",
          duration: 0,
        },
        ">+0.02"
      )
      .then(onEndAnimation);
  };

  const onSetTimeLeft = () => {
    const value = Math.floor((expiryDate - Date.now()) / 1000);

    const minus = 0;

    setTimeLeft(value - minus);

    if (value - minus === 0 || value < 0) {
      clearInterval(interval.current);

      if (currentParticipantIndex === 0 && items.length === 1) {
        deleteFirstParticipant();
        clearInterval(interval.current);

        return setIsWinner(true);
      }

      if (value < 0) {
        return deleteFirstParticipant();
      }

      setTimeLeft(0);
      startExitAnimation();
    }
  };

  const onEndAnimation = () => {
    deleteFirstParticipant();

    interval.current = setInterval(() => {
      onSetTimeLeft();
    }, 1000);
  };

  const getCurrentTop = () => {
    if (viewWidth >= 1400) {
      return "-62px";
    }
    if (viewWidth >= 576) {
      return "-60px";
    }
    return "-57px";
  };

  useEffect(() => {
    if (!items.length) return;

    clearInterval(interval.current);
    onSetTimeLeft();

    interval.current = setInterval(() => {
      onSetTimeLeft();
    }, 1000);

    return () => clearInterval(interval.current);
  }, [expiryDate, items]);

  const renderLineItem = (
    { wallet_address, number, user_id, isFixed = false },
    idx
  ) => {
    const currentNumber = idx === 25 || isFixed ? number : idx + 1;
    const isCurrentUser = user_id === currentParticipant?.user_id;
    const isUserWithoutNotifications =
      !isNotificationsEnabled && user_id && isCurrentUser && idx !== 0;

    return (
      <React.Fragment key={user_id || idx}>
        <div
          className={cn(styles.item, "home-list__item", {
            [styles.item__onlyOne]: items.length === 1,
            first: idx === 0,
            second: idx === 1,
          })}
          key={user_id || idx}
        >
          <span className={styles.num}>{currentNumber}</span>
          <div
            className={cn(styles.text, {
              [styles.withoutNotifications]: isUserWithoutNotifications,
            })}
          >
            <div>
              {!user_id && <Skeleton />}
              {user_id && isCurrentUser && "Вы"}
              {wallet_address && wallet_address.length > 12 && !isCurrentUser
                ? `${wallet_address.slice(0, 5)}...${wallet_address.slice(-5)}`
                : !isCurrentUser && wallet_address}
              {user_id && isCurrentUser && !isFixed && <div ref={ref} />}
            </div>
          </div>
          {isUserWithoutNotifications && (
            <div className={styles.after}>
              <a href={linkToTelegramBot} target="blank">
                Get Notifications
              </a>
            </div>
          )}
          {expiryDate && idx === 0 && (
            <div className={styles.after}>
              <span className={styles.time}>
                00:
                {getCurrentTime(timeLeft)}
              </span>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className={styles.list} ref={listEl}>
        {items.length
          ? items.map(renderLineItem)
          : Array(5).fill(0).map(renderLineItem)}
      </div>
      {ref && currentParticipantIndex !== -1 && (
        <div
          className={`${styles["list-you"]} ${
            ref && !inView ? styles["list-you_show"] : ""
          }`}
        >
          {renderLineItem({
            wallet_address: currentParticipant.wallet_address,
            number: currentParticipant.number,
            user_id: currentParticipant.user_id,
            isFixed: true,
          })}
        </div>
      )}
    </>
  );
};

export default memo(Line);

export const selectModalsVisibility = ({ home }) => home.modalsVisibility;

export const selectHomeEntryTime = ({ home }) => home.entryTime;

export const selectLineParticipants = ({ home }) => home.lineParticipants;

export const selectCurrentParticipant = ({ home }) => {
  const item = home.lineParticipants.find(
    (el) => el.is_notification_enabled !== undefined
  );

  const idx = home.lineParticipants.findIndex(
    (el) => el.is_notification_enabled !== undefined
  );

  return { item, index: idx };
};

export const selectLineTotalCount = ({ home }) => home.lineTotalCount;

export const selectExpiryDate = ({ home }) =>
  home.lineParticipants[0]?.expiry_date;

export const selectIsWinner = ({ home }) => home.isWinner;
export const selectIsNotificationsEnabled = ({ home }) =>
  home.isNotificationsEnabled;

export const selectTelegramInviteLink = ({ user }) => user.telegramInviteLink;

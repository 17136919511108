import React, { memo, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { MOBILE_WIDTH } from "services/constants";
import { selectLineTotalCount } from "services/store/selectors";
import { Tooltip } from "components";

import { AverageTimeLeft } from "./index";

import { ReactComponent as QuestionIcon } from "assets/img/icons/ellipse-question.svg";

const lineData = () => {
  const [firstTooltipVisible, setFirstTooltipVisible] = useState(false);
  const [secondTooltipVisible, setSecondTooltipVisible] = useState(false);

  const lineTotalCount = useSelector(selectLineTotalCount);

  const convertedTotalCount = useMemo(() => {
    if (lineTotalCount >= 1000 && lineTotalCount < 1100) {
      return `${Math.floor(+lineTotalCount / 1000)}k`;
    }
    if (lineTotalCount >= 1100) {
      const currentNumber =
        Number.parseInt((+lineTotalCount / 1000) * 100) / 100;

      if (String(currentNumber).length > 3) {
        return `${String(currentNumber).slice(0, -1)}k`;
      }
      return `${currentNumber}k`;
    }

    return lineTotalCount;
  }, [lineTotalCount]);

  let isMobile;

  if (typeof window !== "undefined") {
    isMobile = window.innerWidth <= MOBILE_WIDTH;
  }

  const renderDataItem = ({
    title,
    value,
    tooltipValue,
    tooltipVisible,
    ...props
  }) => (
    <div className="home-data__item">
      <div className="home-data__title">
        {title}
        <Tooltip
          value={tooltipValue}
          visible={tooltipVisible}
          placement={isMobile ? "left" : "top"}
        >
          <div
            className="home-data__icon-wrapper"
            {...props}
            onClick={isMobile ? props.onClick : null}
          >
            <QuestionIcon
              fill={tooltipVisible ? "#fff" : "rgba(255, 255, 255, 0.4)"}
            />
          </div>
        </Tooltip>
      </div>
      <h3 className="home-data__value">{value}</h3>
    </div>
  );

  const handleDataTooltipClick = (cb) => {
    cb();
    setTimeout(() => {
      cb();
    }, 2000);
  };

  const handleTooltipVisible = (cb) => {
    if (isMobile) return;

    cb();
  };

  return (
    <div className="home-data">
      <AverageTimeLeft
        tooltipVisible={firstTooltipVisible}
        isMobile={isMobile}
        onMouseEnter={() =>
          handleTooltipVisible(() => setFirstTooltipVisible(true))
        }
        onMouseLeave={() =>
          handleTooltipVisible(() => setFirstTooltipVisible(false))
        }
        onClick={() =>
          handleDataTooltipClick(() => setFirstTooltipVisible((prev) => !prev))
        }
      />
      {renderDataItem({
        title: "В очереди",
        value: convertedTotalCount,
        tooltipVisible: secondTooltipVisible,
        tooltipValue: "Людей в очереди",
        onMouseEnter: () =>
          handleTooltipVisible(() => setSecondTooltipVisible(true)),
        onMouseLeave: () =>
          handleTooltipVisible(() => setSecondTooltipVisible(false)),
        onClick: () =>
          handleDataTooltipClick(() =>
            setSecondTooltipVisible((prev) => !prev)
          ),
      })}
    </div>
  );
};

export default memo(lineData);

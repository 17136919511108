import { configureStore } from "@reduxjs/toolkit";

import { homeReducer, userReducer } from "./slices";

const store = configureStore({
  reducer: {
    home: homeReducer,
    user: userReducer,
  },
});

export default store;

import { memo } from "react";
import OriginalSkeleton from "react-loading-skeleton";
import cn from "classnames";

import styles from "./styles.module.scss";
import "react-loading-skeleton/dist/skeleton.css";

const Skeleton = ({ className }) => (
  <OriginalSkeleton className={cn(styles.skeleton, className)} />
);

export default memo(Skeleton);

import React from "react";
import cn from "classnames";
import OriginalTooltip from "rc-tooltip";

import "rc-tooltip/assets/bootstrap_white.css";

const Tooltip = ({ value, children, placement = "top", ...props }) => {
  return (
    <OriginalTooltip
      placement={placement}
      trigger={["hover"]}
      overlay={<span className="tooltip-value">{value}</span>}
      overlayClassName={cn("tooltip", {
        top: placement === "top",
        left: placement === "left",
      })}
      overlayStyle={{
        opacity: 1
      }}
      overlayInnerStyle={{
        background: "#5C3982",
        border: "1px solid #5C3982",
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
      }}
      {...props}
    >
      {children}
    </OriginalTooltip>
  );
};

export default Tooltip;
